import { environment } from "../environments/environment";

export const TargetModUrl = environment.apiUrl + environment.apiPostfix;

export const ApiUrl = {
  sendMail: TargetModUrl + '/api/ContactUs/SendMail',                                   // 聯絡我們 寄信

  login: TargetModUrl + '/User/Frontend/Login',                                         // 登入
  getSalesInfo: TargetModUrl + '/User/Frontend/GetSalesInfo',                           // 取得 業務資訊
  forgetPassword: TargetModUrl + '/User/Frontend/ForgetPassword',                       // 忘記密碼

  getProjects: TargetModUrl + '/Payment/Frontend/GetProjects',                          // 取得 專案清單
  getContracts: TargetModUrl + '/Payment/Frontend/GetContracts',                        // 取得 專案底下的合約清單
  getPaymentBank: TargetModUrl + '/Payment/Frontend/GetPaymentBank',                    // 取得 專案的信託銀行網址
  feedback: TargetModUrl + '/User/Frontend/Feedback',                                   // 意見反饋

  getInvestServices: TargetModUrl + '/Invest/Frontend/GetServices',                     // 取得 股東服務清單
  getFinanceReports: TargetModUrl + '/Invest/Frontend/GetFinanceReports',               // 取得 財務季報清單
  getShareholders: TargetModUrl + '/Invest/Frontend/GetShareholders',                   // 取得 股東會資訊
  getShareholderItems: TargetModUrl + '/Invest/Frontend/GetShareholderItems',           // 取得 股東會項目

  getRules: TargetModUrl + '/Corp/Frontend/GetRules',                                   // 取得 公司規章
  getStakeholders: TargetModUrl + '/Corp/Frontend/GetStakeholders',                     // 取得 利害關係人
  getSustainabilityReport: TargetModUrl + '/Corp/Frontend/GetSustainabilityReport',     // 取得 永續報告書

  getUrbanRedevList: TargetModUrl + '/Redev/Frontend/GetUrbanRedevs',                   // 取得 都更清單
  getUrbanRedev: TargetModUrl + '/Redev/Frontend/GetUrbanRedev',                        // 取得 都更專案資訊
}